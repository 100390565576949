<template>
  <div class="corner-module">
    Copyright©2024 {{ host }} All rights Reserved.
  </div>
</template>

<script>
import '@/css/module/foot-theme-1.scss';

export default {
  name: 'FootModule1',
  props: {
    themeConfig: {
      type: Object,
      default () {
        return {};
      },
    },
    deviceType: {
      type: String,
      default: '',
    },
    host: {
      type: String,
      default: '',
    },
  },
};
</script>
